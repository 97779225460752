export default function NotFound() {
  return (
    <div
      style={{
        fontSize: "24px",
        fontWeight: "500",
        marginTop: "64px",
        width: "100%",
        textAlign: "center",
      }}
    >
      Page not found
    </div>
  );
}
